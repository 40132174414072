@import "../../../../styles/mixins.scss";

$color-gradient-overlay: linear-gradient(180deg, #000000 0%, #000000 100%);

.post {
  &Wrapper {
    height: 350px;
    width: 300px;
    border-radius: 0.675rem;
    position: relative;
    border-radius: 12px;
    // @include gradientOverlay(0.675rem, $color-gradient-overlay);

    @include for-phone-only {
      height: 18rem;
    }
  }

  &Image {
    width: 50%;
    height: 100%;
    border-radius: 0.675rem;
    object-fit: cover;
    object-position: center;
    position: relative;
  }

  &Info {
    @include justify-spaceBetween;
    font-size: 0.75rem;
    flex-direction: column;
    position: absolute;
    color: var(--terinary);
    bottom: 0px;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    z-index: 1;

    span {
      width: fit-content;
      background: #0000004d;
      font-size: 9px;
      padding: 1px 6px;
      border-radius: 30px;
      margin: 0px;
    }

    .count {
      @include justify-align-center;
      background: rgba(0, 0, 0, 0.36);
      border: 0.5px solid rgba(255, 255, 255, 0.12);
      border-radius: 5rem;
      width: max-content;
      padding: 0.125rem 0.25rem;
      > img {
        margin-right: 0.25rem;
      }
    }

    .name {
      font-size: 0.875rem;
      margin: 0.25rem 0;
    }

    .userStatsWrapper {
      @include align-center;

      .postImage {
        margin-left: 0.3rem;
      }
    }
  }
}

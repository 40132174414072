.countryWrapper {
  background-image: url("./flagSprite.png");
  background-repeat: no-repeat;
  background-size: 100% 49494%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  box-sizing: content-box;
  background-size: 160%;
  background-clip: content-box;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-position: 45% 0%;
}
.countryWrapper.CH,
.countryWrapper.NP {
  box-shadow: none !important;
}
.countryWrapper.DZ {
  background-position: center 0.2287%;
}
.countryWrapper.AO {
  background-position: center 0.4524%;
}
.countryWrapper.BJ {
  background-position: center 0.6721%;
}
.countryWrapper.BW {
  background-position: center 0.8958%;
}
.countryWrapper.BF {
  background-position: center 1.1162%;
}
.countryWrapper.BI {
  background-position: center 1.3379%;
}
.countryWrapper.CM {
  background-position: center 1.5589%;
}
.countryWrapper.CV {
  background-position: center 1.7805%;
}
.countryWrapper.CF {
  background-position: center 2.0047%;
}
.countryWrapper.TD {
  background-position: center 2.2247%;
}
.countryWrapper.CD {
  background-position: left 2.4467%;
}
.countryWrapper.DJ {
  background-position: left 2.6674%;
}
.countryWrapper.EG {
  background-position: center 2.8931%;
}
.countryWrapper.GQ {
  background-position: center 3.1125%;
}
.countryWrapper.ER {
  background-position: left 3.3325%;
}
.countryWrapper.ET {
  background-position: center 3.5542%;
}
.countryWrapper.GA {
  background-position: center 3.7759%;
}
.countryWrapper.GM {
  background-position: center 4.0015%;
}
.countryWrapper.GH {
  background-position: center 4.2229%;
}
.countryWrapper.GN {
  background-position: center 4.441%;
}
.countryWrapper.GW {
  background-position: left 4.66663%;
}
.countryWrapper.CI {
  background-position: center 4.8844%;
}
.countryWrapper.KE {
  background-position: center 5.1061%;
}
.countryWrapper.LS {
  background-position: center 5.3298%;
}
.countryWrapper.LR {
  background-position: left 5.5495%;
}
.countryWrapper.LY {
  background-position: center 5.7712%;
}
.countryWrapper.MG {
  background-position: center 5.994%;
}
.countryWrapper.MW {
  background-position: center 6.2156%;
}
.countryWrapper.ML {
  background-position: center 6.4363%;
}
.countryWrapper.MR {
  background-position: center 6.658%;
}
.countryWrapper.MU {
  background-position: center 6.8805%;
}
.countryWrapper.YT {
  background-position: center 7.1038%;
}
.countryWrapper.MA {
  background-position: center 7.3231%;
}
.countryWrapper.MZ {
  background-position: left 7.5448%;
}
.countryWrapper.NA {
  background-position: left 7.7661%;
}
.countryWrapper.NE {
  background-position: center 7.98937%;
}
.countryWrapper.NG {
  background-position: center 8.2099%;
}
.countryWrapper.CG {
  background-position: center 8.4316%;
}
.countryWrapper.RE {
  background-position: center 8.6533%;
}
.countryWrapper.RW {
  background-position: right 8.875%;
}
.countryWrapper.SH {
  background-position: center 9.0967%;
}
.countryWrapper.ST {
  background-position: center 9.32237%;
}
.countryWrapper.SN {
  background-position: center 9.5426%;
}
.countryWrapper.SC {
  background-position: left 9.7628%;
}
.countryWrapper.SL {
  background-position: center 9.9845%;
}
.countryWrapper.SO {
  background-position: center 10.2052%;
}
.countryWrapper.ZA {
  background-position: left 10.4269%;
}
.countryWrapper.SS {
  background-position: left 10.6486%;
}
.countryWrapper.SD {
  background-position: center 10.8703%;
}
.countryWrapper.SR {
  background-position: center 11.0945%;
}
.countryWrapper.SZ {
  background-position: center 11.3135%;
}
.countryWrapper.TG {
  background-position: left 11.5354%;
}
.countryWrapper.TN {
  background-position: center 11.7593%;
}
.countryWrapper.UG {
  background-position: center 11.9799%;
}
.countryWrapper.TZ {
  background-position: center 12.2005%;
}
.countryWrapper.EH {
  background-position: center 12.4222%;
}
.countryWrapper.YE {
  background-position: center 12.644%;
}
.countryWrapper.ZM {
  background-position: center 12.8664%;
}
.countryWrapper.ZW {
  background-position: left 13.0873%;
}
.countryWrapper.AI {
  background-position: center 13.309%;
}
.countryWrapper.AG {
  background-position: center 13.5307%;
}
.countryWrapper.AR {
  background-position: center 13.7524%;
}
.countryWrapper.AW {
  background-position: left 13.9741%;
}
.countryWrapper.BS {
  background-position: left 14.1958%;
}
.countryWrapper.BB {
  background-position: center 14.4175%;
}
.countryWrapper.BQ {
  background-position: center 14.6415%;
}
.countryWrapper.BZ {
  background-position: center 14.8609%;
}
.countryWrapper.BM {
  background-position: center 15.0826%;
}
.countryWrapper.BO {
  background-position: center 15.306%;
}
.countryWrapper.VG {
  background-position: center 15.528%;
}
.countryWrapper.BR {
  background-position: center 15.7496%;
}
.countryWrapper.CA {
  background-position: center 15.9694%;
}
.countryWrapper.KY {
  background-position: center 16.1911%;
}
.countryWrapper.CL {
  background-position: left 16.4128%;
}
.countryWrapper.CO {
  background-position: left 16.6345%;
}
.countryWrapper.KM {
  background-position: center 16.8562%;
}
.countryWrapper.CR {
  background-position: center 17.0779%;
}
.countryWrapper.CU {
  background-position: left 17.2996%;
}
.countryWrapper.CW {
  background-position: center 17.5213%;
}
.countryWrapper.DM {
  background-position: center 17.743%;
}
.countryWrapper.DO {
  background-position: center 17.968%;
}
.countryWrapper.EC {
  background-position: center 18.1864%;
}
.countryWrapper.SV {
  background-position: center 18.4081%;
}
.countryWrapper.FK {
  background-position: center 18.6298%;
}
.countryWrapper.GF {
  background-position: center 18.8515%;
}
.countryWrapper.GL {
  background-position: left 19.0732%;
}
.countryWrapper.GD {
  background-position: center 19.2987%;
}
.countryWrapper.GP {
  background-position: center 19.518%;
}
.countryWrapper.GT {
  background-position: center 19.7383%;
}
.countryWrapper.GY {
  background-position: center 19.96%;
}
.countryWrapper.HT {
  background-position: center 20.1817%;
}
.countryWrapper.HN {
  background-position: center 20.4034%;
}
.countryWrapper.JM {
  background-position: center 20.6241%;
}
.countryWrapper.MQ {
  background-position: center 20.8468%;
}
.countryWrapper.MX {
  background-position: center 21.0685%;
}
.countryWrapper.MS {
  background-position: center 21.2902%;
}
.countryWrapper.NI {
  background-position: center 21.5119%;
}
.countryWrapper.PA {
  background-position: center 21.7336%;
}
.countryWrapper.PY {
  background-position: center 21.9553%;
}
.countryWrapper.PE {
  background-position: center 22.177%;
}
.countryWrapper.PR {
  background-position: left 22.4002%;
}
.countryWrapper.BL {
  background-position: center 22.6204%;
}
.countryWrapper.KN {
  background-position: center 22.8421%;
}
.countryWrapper.LC {
  background-position: center 23.0638%;
}
.countryWrapper.PM {
  background-position: center 23.2855%;
}
.countryWrapper.VC {
  background-position: center 23.5072%;
}
.countryWrapper.SX {
  background-position: left 23.732%;
}
.countryWrapper.TT {
  background-position: center 23.9506%;
}
.countryWrapper.TC {
  background-position: center 24.1723%;
}
.countryWrapper.US {
  background-position: center 24.392%;
}
.countryWrapper.VI {
  background-position: center 24.6157%;
}
.countryWrapper.UY {
  background-position: left 24.8374%;
}
.countryWrapper.VE {
  background-position: center 25.0591%;
}
.countryWrapper.AB {
  background-position: center 25.279%;
}
.countryWrapper.AF {
  background-position: center 25.5025%;
}
.countryWrapper.AZ {
  background-position: center 25.7242%;
}
.countryWrapper.BD {
  background-position: center 25.9459%;
}
.countryWrapper.BT {
  background-position: center 26.1676%;
}
.countryWrapper.BN {
  background-position: center 26.3885%;
}
.countryWrapper.KH {
  background-position: center 26.611%;
}
.countryWrapper.CN {
  background-position: left 26.8327%;
}
.countryWrapper.GE {
  background-position: center 27.0544%;
}
.countryWrapper.HK {
  background-position: center 27.2761%;
}
.countryWrapper.IN {
  background-position: center 27.4978%;
}
.countryWrapper.ID {
  background-position: center 27.7195%;
}
.countryWrapper.JP {
  background-position: center 27.9412%;
}
.countryWrapper.KZ {
  background-position: center 28.1615%;
}
.countryWrapper.LA {
  background-position: center 28.3846%;
}
.countryWrapper.MO {
  background-position: center 28.6063%;
}
.countryWrapper.MY {
  background-position: center 28.829%;
}
.countryWrapper.MV {
  background-position: center 29.0497%;
}
.countryWrapper.MN {
  background-position: left 29.2714%;
}
.countryWrapper.MM {
  background-position: center 29.4931%;
}
.countryWrapper.NP {
  background-position: left 29.7148%;
}
.countryWrapper.KP {
  background-position: left 29.9365%;
}
.countryWrapper.MP {
  background-position: center 30.1582%;
}
.countryWrapper.PW {
  background-position: center 30.3799%;
}
.countryWrapper.PG {
  background-position: center 30.6016%;
}
.countryWrapper.PH {
  background-position: left 30.8233%;
}
.countryWrapper.SG {
  background-position: left 31.045%;
}
.countryWrapper.KR {
  background-position: center 31.2667%;
}
.countryWrapper.LK {
  background-position: right 31.4884%;
}
.countryWrapper.TW {
  background-position: left 31.7101%;
}
.countryWrapper.TJ {
  background-position: center 31.9318%;
}
.countryWrapper.TH {
  background-position: center 32.1535%;
}
.countryWrapper.TL {
  background-position: left 32.3752%;
}
.countryWrapper.TM {
  background-position: center 32.5969%;
}
.countryWrapper.VN {
  background-position: center 32.8186%;
}
.countryWrapper.AX {
  background-position: center 33.0403%;
}
.countryWrapper.AL {
  background-position: center 33.25975%;
}
.countryWrapper.AD {
  background-position: center 33.4837%;
}
.countryWrapper.AM {
  background-position: center 33.7054%;
}
.countryWrapper.AT {
  background-position: center 33.9271%;
}
.countryWrapper.BY {
  background-position: left 34.1488%;
}
.countryWrapper.BE {
  background-position: center 34.3705%;
}
.countryWrapper.BA {
  background-position: center 34.5922%;
}
.countryWrapper.BG {
  background-position: center 34.8139%;
}
.countryWrapper.HR {
  background-position: center 35.0356%;
}
.countryWrapper.CY {
  background-position: center 35.2555%;
}
.countryWrapper.CZ {
  background-position: left 35.479%;
}
.countryWrapper.DK {
  background-position: center 35.7007%;
}
.countryWrapper.EE {
  background-position: center 35.9224%;
}
.countryWrapper.FO {
  background-position: center 36.1441%;
}
.countryWrapper.FI {
  background-position: center 36.3658%;
}
.countryWrapper.FR {
  background-position: center 36.5875%;
}
.countryWrapper.DE {
  background-position: center 36.8092%;
}
.countryWrapper.GI {
  background-position: center 37.0309%;
}
.countryWrapper.GR {
  background-position: left 37.2526%;
}
.countryWrapper.GG {
  background-position: center 37.4743%;
}
.countryWrapper.HU {
  background-position: center 37.696%;
}
.countryWrapper.IS {
  background-position: center 37.9177%;
}
.countryWrapper.IE {
  background-position: center 38.1394%;
}
.countryWrapper.IM {
  background-position: center 38.3611%;
}
.countryWrapper.IT {
  background-position: center 38.5828%;
}
.countryWrapper.JE {
  background-position: center 38.8045%;
}
.countryWrapper.XK {
  background-position: center 39.0262%;
}
.countryWrapper.LV {
  background-position: center 39.2479%;
}
.countryWrapper.LI {
  background-position: left 39.4696%;
}
.countryWrapper.LT {
  background-position: center 39.6913%;
}
.countryWrapper.LU {
  background-position: center 39.913%;
}
.countryWrapper.MT {
  background-position: left 40.1347%;
}
.countryWrapper.MD {
  background-position: center 40.3564%;
}
.countryWrapper.MC {
  background-position: center 40.5781%;
}
.countryWrapper.ME {
  background-position: center 40.7998%;
}
.countryWrapper.NL {
  background-position: center 41.0215%;
}
.countryWrapper.MK {
  background-position: center 41.2432%;
}
.countryWrapper.NO {
  background-position: center 41.4649%;
}
.countryWrapper.PL {
  background-position: center 41.6866%;
}
.countryWrapper.PT {
  background-position: center 41.9083%;
}
.countryWrapper.RO {
  background-position: center 42.13%;
}
.countryWrapper.RU {
  background-position: center 42.3517%;
}
.countryWrapper.SM {
  background-position: center 42.5734%;
}
.countryWrapper.RS {
  background-position: center 42.7951%;
}
.countryWrapper.SK {
  background-position: center 43.0168%;
}
.countryWrapper.SI {
  background-position: center 43.2385%;
}
.countryWrapper.ES {
  background-position: left 43.4602%;
}
.countryWrapper.SE {
  background-position: center 43.6819%;
}
.countryWrapper.CH {
  background-position: center 43.9036%;
}
.countryWrapper.TR {
  background-position: center 44.1253%;
}
.countryWrapper.UA {
  background-position: center 44.347%;
}
.countryWrapper.GB {
  background-position: center 44.5687%;
}
.countryWrapper.VA {
  background-position: right 44.7904%;
}
.countryWrapper.BH {
  background-position: center 45.0121%;
}
.countryWrapper.IR {
  background-position: center 45.2338%;
}
.countryWrapper.IQ {
  background-position: center 45.4555%;
}
.countryWrapper.IL {
  background-position: center 45.6772%;
}
.countryWrapper.KW {
  background-position: left 45.897%;
}
.countryWrapper.JO {
  background-position: left 46.1206%;
}
.countryWrapper.KG {
  background-position: center 46.3423%;
}
.countryWrapper.LB {
  background-position: center 46.561%;
}
.countryWrapper.OM {
  background-position: left 46.7857%;
}
.countryWrapper.PK {
  background-position: 75% 47.0074%;
}
.countryWrapper.PS {
  background-position: center 47.2291%;
}
.countryWrapper.QA {
  background-position: center 47.4508%;
}
.countryWrapper.SA {
  background-position: center 47.6725%;
}
.countryWrapper.SY {
  background-position: center 47.8942%;
}
.countryWrapper.AE {
  background-position: center 48.1159%;
}
.countryWrapper.UZ {
  background-position: left 48.3376%;
}
.countryWrapper.AS {
  background-position: right 48.5593%;
}
.countryWrapper.AU {
  background-position: center 48.781%;
}
.countryWrapper.CX {
  background-position: center 49.002%;
}
.countryWrapper.CC {
  background-position: center 49.2244%;
}
.countryWrapper.CK {
  background-position: center 49.4445%;
}
.countryWrapper.FJ {
  background-position: center 49.6678%;
}
.countryWrapper.PF {
  background-position: center 49.8895%;
}
.countryWrapper.GU {
  background-position: center 50.1112%;
}
.countryWrapper.KI {
  background-position: center 50.3329%;
}
.countryWrapper.MH {
  background-position: left 50.5546%;
}
.countryWrapper.FM {
  background-position: center 50.7763%;
}
.countryWrapper.NC {
  background-position: center 50.998%;
}
.countryWrapper.NZ {
  background-position: center 51.2197%;
}
.countryWrapper.NR {
  background-position: left 51.4414%;
}
.countryWrapper.NU {
  background-position: center 51.6631%;
}
.countryWrapper.NF {
  background-position: center 51.8848%;
}
.countryWrapper.WS {
  background-position: left 52.1065%;
}
.countryWrapper.SB {
  background-position: left 52.3282%;
}
.countryWrapper.TK {
  background-position: center 52.5499%;
}
.countryWrapper.TO {
  background-position: left 52.7716%;
}
.countryWrapper.TV {
  background-position: center 52.9933%;
}
.countryWrapper.VU {
  background-position: left 53.215%;
}
.countryWrapper.WF {
  background-position: center 53.4385%;
}
.countryWrapper.AQ {
  background-position: center 53.6584%;
}
.countryWrapper.EU {
  background-position: center 53.875%;
}
.countryWrapper.JR {
  background-position: center 54.099%;
}
.countryWrapper.OLY {
  background-position: center 54.32%;
}
.countryWrapper.UN {
  background-position: center 54.54%;
}
.countryWrapper.TD.ff-round,
.countryWrapper.GN.ff-round,
.countryWrapper.CI.ff-round,
.countryWrapper.ML.ff-round,
.countryWrapper.NG.ff-round,
.countryWrapper.BE.ff-round,
.countryWrapper.FR.ff-round,
.countryWrapper.IE.ff-round,
.countryWrapper.IT.ff-round,
.countryWrapper.RO.ff-round {
  background-size: 100% 50000%;
}
